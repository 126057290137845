// export const BASE_URL = "http://localhost:8000/";
// export const IMAGE_URL = "http://localhost:8000/images/";
// export const DOC_URL = "http://localhost:8000/document/";
// export const CHECKIN_URL = "http://192.168.1.10/";
// export const BILL_URL = "http://127.0.0.1/sp_print/index.php/";

export const BASE_URL = "https://api.sp-auto.co.th/"
// export const BASE_URL = "http://103.13.230.71:4555/sp_api/public/index.php/"

export const IMAGE_URL = "https://api.sp-auto.co.th/images/"
export const DOC_URL = "https://api.sp-auto.co.th/doc/"
export const CHECKIN_URL = "https://api.sp-auto.co.th/checkin/";
export const BILL_URL = "https://api.sp-auto.co.th/bill/";

// export const IMAGE_URL = "http://103.13.230.71:4555/sp_api/public/images/"
// export const DOC_URL = "http://103.13.230.71:4555/sp_api/public/"
// export const CHECKIN_URL = "http://103.13.230.71:4555/";
// export const BILL_URL = "http://103.13.230.71:4555/sp_print/index.php/";

// // // // 


