import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getLocaleDateFormat } from '@angular/common';
import { Observable } from 'rxjs';
import { BASE_URL } from '../base_url_config';

@Injectable({
  providedIn: 'root',
})
export class SettingService {

  constructor(private http: HttpClient) { }



  getAllBranch(): Observable<any> {
    const url = BASE_URL + 'getAllBranch';
    return this.http.get<any>(url);
  }


  getAllHelpdeskProblem(): Observable<any> {
    const url = BASE_URL + 'getAllHelpDeskProblem';
    return this.http.get<any>(url);
  }
  getAllDepartment(): Observable<any> {
    const url = BASE_URL + 'getAllDepartment';
    return this.http.get<any>(url);
  }


  getAllPosition(): Observable<any> {
    const url = BASE_URL + 'getAllPosition';
    return this.http.get<any>(url);
  }



  getPosition(id): Observable<any> {
    const url = BASE_URL + 'getPosition/' + id;
    return this.http.get<any>(url);
  }

  getBranch(id): Observable<any> {
    const url = BASE_URL + 'getBranch/' + id;
    return this.http.get<any>(url);
  }


  getDepartment(id): Observable<any> {
    const url = BASE_URL + 'getDepartment/' + id;
    return this.http.get<any>(url);
  }

  newPosition(data): Observable<any> {
    const url = BASE_URL + 'addPosition';
    return this.http.post<any>(url, data);
  }

  editPosition(data): Observable<any> {
    const url = BASE_URL + 'editPosition';
    return this.http.post<any>(url, data);
  }

  editBranch(data): Observable<any> {
    const url = BASE_URL + 'editBranch';
    return this.http.post<any>(url, data);
  }


  editDepartment(data): Observable<any> {
    const url = BASE_URL + 'editDepartment';
    return this.http.post<any>(url, data);
  }

  newBranch(data): Observable<any> {
    const url = BASE_URL + 'addBranch';
    return this.http.post<any>(url, data);
  }

  newDepartment(data): Observable<any> {
    const url = BASE_URL + 'addDepartment';
    return this.http.post<any>(url, data);
  }


  deletePosition(data): Observable<any> {
    const url = BASE_URL + 'deletePosition';
    return this.http.post<any>(url, data);
  }

  deleteBranch(data): Observable<any> {
    const url = BASE_URL + 'deleteBranch';
    return this.http.post<any>(url, data);
  }


  deleteDepartment(data): Observable<any> {
    const url = BASE_URL + 'deleteDepartment';
    return this.http.post<any>(url, data);
  }


  getData(): Observable<any> {
    const url = BASE_URL + 'getCompanyProfile';
    return this.http.get<any>(url);
  }


  updateCompanyProfile(data) {
    const url = BASE_URL + 'updateCompanyProfile';
    return this.http.post<any>(url, data);
  }
  // editFoodOrderByManager(data) {
  //   return this.http.post(API_URL + '/editFoodOrderByManager', data);
  // }


  getAllStaff(): Observable<any> {
    const url = BASE_URL + 'getAllStaff';
    return this.http.get<any>(url);
  }

  deleteStaff(data): Observable<any> {
    const url = BASE_URL + 'deleteStaff';
    return this.http.post<any>(url, data);
  }


  addStaff(data): Observable<any> {
    const url = BASE_URL + 'addStaff';
    return this.http.post<any>(url, data);
  }

  editStaff(data): Observable<any> {
    const url = BASE_URL + 'updateStaff';
    return this.http.post<any>(url, data);
  }


  getStaffId(id): Observable<any> {
    const url = BASE_URL + 'getStaff/' + id;
    return this.http.get<any>(url);
  }

  getDataMenuByUserId(staff_id): Observable<any> {
    const url = BASE_URL + 'getMenuByUserId';
    //console.log(url)
    return this.http.post<any>(url, staff_id);
  }

  getDataMenu(menu_id): Observable<any> {
    const url = BASE_URL + 'getMenuById';
    return this.http.post<any>(url, menu_id);
  }

  login(data): Observable<any> {
    const url = BASE_URL + 'login';
    return this.http.post<any>(url, data);
  }

  change_password(data): Observable<any> {
    const url = BASE_URL + 'change_password';
    return this.http.post<any>(url, data);
  }

  getGroupMenu(): Observable<any> {
    const url = BASE_URL + 'getGroupMenu/';
    return this.http.get<any>(url);
  }

  getAllMenu(): Observable<any> {
    const url = BASE_URL + 'getMenu/';
    return this.http.get<any>(url);
  }

  getMenuByGroupId(data) {
    const url = BASE_URL + 'getMenuByGroupId';
    return this.http.post<any>(url, data);
  }

  deleteMenuById(data) {
    const url = BASE_URL + 'delMenuById';
    return this.http.post<any>(url, data);
  }

  addByUserId(data) {
    const url = BASE_URL + 'addByUserId';
    return this.http.post<any>(url, data);
  }

  getSettingAnnouncement() {
    const url = BASE_URL + 'getSettingAnnouncement';
    return this.http.get<any>(url);
  }


  getAnnouncement() {
    const url = BASE_URL + 'getAnnouncement';
    return this.http.get<any>(url);
  }

  addAnnouncement(data) {
    const url = BASE_URL + 'addAnnouncement';
    return this.http.post<any>(url, data);
  }

  editAnnouncement(data) {
    const url = BASE_URL + 'editAnnouncement';
    return this.http.post<any>(url, data);
  }

  deleteAnnouncement(data) {
    const url = BASE_URL + 'deleteAnnouncement';
    return this.http.post<any>(url, data);
  }


  getGoalMonth(data) {
    const url = BASE_URL + 'getGoalMonth';
    return this.http.post<any>(url, data);
  }


  setGoal(data) {
    const url = BASE_URL + 'setGoal';
    return this.http.post<any>(url, data);
  }



  checkLastDay(user_id) {
    const url = BASE_URL + 'checkLastDay/' + user_id;
    return this.http.get<any>(url);
  }


  // loadFileWindow() {
  //   this.http.get('Users/ttech/test.txt', { responseType: 'text' }).subscribe(data => {
  //     console.log(data);
  //   })
  // }

}
